import vmService from "./vmService";
import api from "./api";

export default {
    api: 'resource',
    /**
     * Get all user resource by user id
     * @param {string|number} id User id
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get("users/" + id + "/resources").then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get all Resources from User
     * @param id
     * @returns {Promise}
     */
    getAll(id){
        return new Promise((resolve, reject) => {
            this.get(id).then(response => {
                resolve(response.data ? Object.values(response.data) : []);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     *
     * @param data
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     *
     * @param data
     * @returns {Promise}
     */
    edit(data){
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     *
     * @param {array} data ids to delete
     * @returns {Promise}
     */
    delete(data){
        return new Promise((resolve, reject) => {
            api().delete(this.api, { data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
};
