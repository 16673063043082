import api from "./api";

export default {
    api: 'registryKeys',
    /**
     * Get all keys
     * @returns {Promise}
     */
    list(){
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get key by id
     * @param {string|number} id Key ID
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get all resource types
     * @returns {Promise}
     */
    getResourceTypes(){
        return new Promise((resolve, reject) => {
            api().get('resources/types').then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get all resources from resource type
     * @param {string} type Resource type
     * @returns {Promise}
     */
    getResources(type){
        return new Promise((resolve, reject) => {
            api().get('resources/' + type).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Redeem a key
     * @param {string} key Key Attribute from Key Object (not ID)
     * @returns {Promise}
     */
    redeem(key) {
        return new Promise((resolve, reject) => {
            api().post(this.api + '/redeem/' + key, {}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new key
     * @param {objects} data Key objects
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update a key by id
     * @param {objects} data Key object with id
     * @returns {Promise}
     */
    edit(data){
        return new Promise((resolve, reject) => {
            api().put(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more keys by id
     * @param {array} data Array of objects
     * @returns {Promise}
     */
    delete(data){
        return new Promise((resolve, reject) => {
            api().delete(this.api, {data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
};
