import api from "./api";
const osList = {
    "DOS": "MS-DOS.",
    "WIN_31": "Windows 3.1",
    "WIN_95": "Windows 95",
    "WIN_98": "Windows 98",
    "WIN_ME": "Windows Millennium Edition",
    "WIN_NT": "Windows NT 4",
    "WIN_2000_PRO": "Windows 2000 Professional",
    "WIN_2000_SERV": "Windows 2000 Server",
    "WIN_2000_ADV_SERV": "Windows 2000 Advanced Server",
    "WIN_XP_HOME": "Windows XP Home Edition",
    "WIN_XP_PRO": "Windows XP Professional",
    "WIN_XP_PRO_64": "Windows XP Professional Edition (64 bit)",
    "WIN_NET_WEB": "Windows Server 2003, Web Edition",
    "WIN_NET_STANDARD": "Windows Server 2003, Standard Edition",
    "WIN_NET_ENTERPRISE": "Windows Server 2003, Enterprise Edition",
    "WIN_NET_DATACENTER": "Windows Server 2003, Datacenter Edition",
    "WIN_NET_BUSINESS": "Windows Small Business Server 2003",
    "WIN_NET_STANDARD_64": "Windows Server 2003, Standard Edition (64 bit)",
    "WIN_NET_ENTERPRISE_64": "Windows Server 2003, Enterprise Edition (64 bit)",
    "WIN_LONGHORN": "Windows Longhorn (experimental)",
    "WIN_LONGHORN_64": "Windows Longhorn (64 bit) (experimental)",
    "WIN_NET_DATACENTER_64": "Windows Server 2003, Datacenter Edition (64 bit) (experimental)",
    "WIN_VISTA": "Windows Vista",
    "WIN_VISTA_64": "Windows Vista (64 bit)",
    "WINDOWS_7": "Windows 7",
    "WINDOWS_7_64": "Windows 7 (64 bit)",
    "WINDOWS_7_SERVER_64": "Windows Server 2008 R2 (64 bit)",
    "WINDOWS_8": "Windows 8",
    "WINDOWS_8_64": "Windows 8 (64 bit)",
    "WINDOWS_8_SERVER_64": "Windows 8 Server (64 bit)",
    "WINDOWS_9": "Windows 10",
    "WINDOWS_9_64": "Windows 10 (64 bit)",
    "WINDOWS_9_SERVER_64": "Windows 10 Server (64 bit)",
    "WINDOWS_HYPERV": "Windows Hyper-V",
    "FREEBSD": "FreeBSD 10 or earlier",
    "FREEBSD_64": "FreeBSD 10 x64 or earlier",
    "FREEBSD_11": "FreeBSD 11. This constant was added in vSphere API 6.7",
    "FREEBSD_12": "FreeBSD 12 or later. This constant was added in vSphere API 6.7",
    "FREEBSD_11_64": "FreeBSD 11 x64. This constant was added in vSphere API 6.7",
    "FREEBSD_12_64": "FreeBSD 12 x64 or later. This constant was added in vSphere API 6.7",
    "REDHAT": "Red Hat Linux 2.1",
    "RHEL_2": "Red Hat Enterprise Linux 2",
    "RHEL_3": "Red Hat Enterprise Linux 3",
    "RHEL_3_64": "Red Hat Enterprise Linux 3 (64 bit)",
    "RHEL_4": "Red Hat Enterprise Linux 4",
    "RHEL_4_64": "Red Hat Enterprise Linux 4 (64 bit)",
    "RHEL_5": "Red Hat Enterprise Linux 5",
    "RHEL_5_64": "Red Hat Enterprise Linux 5 (64 bit) (experimental)",
    "RHEL_6": "Red Hat Enterprise Linux 6",
    "RHEL_6_64": "Red Hat Enterprise Linux 6 (64 bit)",
    "RHEL_7": "Red Hat Enterprise Linux 7",
    "RHEL_7_64": "Red Hat Enterprise Linux 7 (64 bit)",
    "RHEL_8_64": "Red Hat Enterprise Linux 8 (64 bit). This constant was added in vSphere API 6.7",
    "CENTOS": "CentOS 4/5",
    "CENTOS_64": "CentOS 4/5 (64-bit)",
    "CENTOS_6": "CentOS 6",
    "CENTOS_6_64": "CentOS 6 (64-bit)",
    "CENTOS_7": "CentOS 7",
    "CENTOS_7_64": "CentOS 7 (64-bit)",
    "CENTOS_8_64": "CentOS 8 (64-bit). This constant was added in vSphere API 6.7",
    "ORACLE_LINUX": "Oracle Linux 4/5",
    "ORACLE_LINUX_64": "Oracle Linux 4/5 (64-bit)",
    "ORACLE_LINUX_6": "Oracle Linux 6",
    "ORACLE_LINUX_6_64": "Oracle Linux 6 (64-bit)",
    "ORACLE_LINUX_7": "Oracle Linux 7",
    "ORACLE_LINUX_7_64": "Oracle Linux 7 (64-bit)",
    "ORACLE_LINUX_8_64": "Oracle Linux 8 (64-bit). This constant was added in vSphere API 6.7",
    "SUSE": "Suse Linux",
    "SUSE_64": "Suse Linux (64 bit)",
    "SLES": "Suse Linux Enterprise Server 9",
    "SLES_64": "Suse Linux Enterprise Server 9 (64 bit)",
    "SLES_10": "Suse linux Enterprise Server 10",
    "SLES_10_64": "Suse Linux Enterprise Server 10 (64 bit) (experimental)",
    "SLES_11": "Suse linux Enterprise Server 11",
    "SLES_11_64": "Suse Linux Enterprise Server 11 (64 bit)",
    "SLES_12": "Suse linux Enterprise Server 12",
    "SLES_12_64": "Suse Linux Enterprise Server 12 (64 bit)",
    "SLES_15_64": "Suse Linux Enterprise Server 15 (64 bit). This constant was added in vSphere API 6.7",
    "NLD_9": "Novell Linux Pool 9",
    "OES": "Open Enterprise Server",
    "SJDS": "Sun Java Pool System",
    "MANDRAKE": "Mandrake Linux",
    "MANDRIVA": "Mandriva Linux",
    "MANDRIVA_64": "Mandriva Linux (64 bit)",
    "TURBO_LINUX": "Turbolinux",
    "TURBO_LINUX_64": "Turbolinux (64 bit)",
    "UBUNTU": "Ubuntu Linux",
    "UBUNTU_64": "Ubuntu Linux (64 bit)",
    "DEBIAN_4": "Debian GNU/Linux 4",
    "DEBIAN_4_64": "Debian GNU/Linux 4 (64 bit)",
    "DEBIAN_5": "Debian GNU/Linux 5",
    "DEBIAN_5_64": "Debian GNU/Linux 5 (64 bit)",
    "DEBIAN_6": "Debian GNU/Linux 6",
    "DEBIAN_6_64": "Debian GNU/Linux 6 (64 bit)",
    "DEBIAN_7": "Debian GNU/Linux 7",
    "DEBIAN_7_64": "Debian GNU/Linux 7 (64 bit)",
    "DEBIAN_8": "Debian GNU/Linux 8",
    "DEBIAN_8_64": "Debian GNU/Linux 8 (64 bit)",
    "DEBIAN_9": "Debian GNU/Linux 9",
    "DEBIAN_9_64": "Debian GNU/Linux 9 (64 bit)",
    "DEBIAN_10": "Debian GNU/Linux 10",
    "DEBIAN_10_64": "Debian GNU/Linux 10 (64 bit)",
    "ASIANUX_3": "Asianux Server 3",
    "ASIANUX_3_64": "Asianux Server 3 (64 bit)",
    "ASIANUX_4": "Asianux Server 4",
    "ASIANUX_4_64": "Asianux Server 4 (64 bit)",
    "ASIANUX_5_64": "Asianux Server 5 (64 bit)",
    "ASIANUX_7_64": "Asianux Server 7 (64 bit)",
    "ASIANUX_8_64": "Asianux Server 8 (64 bit). This constant was added in vSphere API 6.7",
    "OPENSUSE": "OpenSUSE Linux",
    "OPENSUSE_64": "OpenSUSE Linux (64 bit)",
    "FEDORA": "Fedora Linux",
    "FEDORA_64": "Fedora Linux (64 bit)",
    "COREOS_64": "CoreOS Linux (64 bit)",
    "VMWARE_PHOTON_64": "VMware Photon (64 bit)",
    "OTHER_24X_LINUX": "Linux 2.4x Kernel",
    "OTHER_24X_LINUX_64": "Linux 2.4x Kernel (64 bit) (experimental)",
    "OTHER_26X_LINUX": "Linux 2.6x Kernel",
    "OTHER_26X_LINUX_64": "Linux 2.6x Kernel (64 bit) (experimental)",
    "OTHER_3X_LINUX": "Linux 3.x Kernel",
    "OTHER_3X_LINUX_64": "Linux 3.x Kernel (64 bit)",
    "OTHER_4X_LINUX": "Linux 4.x Kernel. This constant was added in vSphere API 6.7",
    "OTHER_4X_LINUX_64": "Linux 4.x Kernel (64 bit). This constant was added in vSphere API 6.7",
    "OTHER_LINUX": "Linux 2.2x Kernel",
    "GENERIC_LINUX": "Other Linux",
    "OTHER_LINUX_64": "Linux (64 bit) (experimental)",
    "SOLARIS_6": "Solaris 6",
    "SOLARIS_7": "Solaris 7",
    "SOLARIS_8": "Solaris 8",
    "SOLARIS_9": "Solaris 9",
    "SOLARIS_10": "Solaris 10 (32 bit) (experimental)",
    "SOLARIS_10_64": "Solaris 10 (64 bit) (experimental)",
    "SOLARIS_11_64": "Solaris 11 (64 bit)",
    "OS2": "OS/2",
    "ECOMSTATION": "eComStation 1.x",
    "ECOMSTATION_2": "eComStation 2.0",
    "NETWARE_4": "Novell NetWare 4",
    "NETWARE_5": "Novell NetWare 5.1",
    "NETWARE_6": "Novell NetWare 6.x",
    "OPENSERVER_5": "SCO OpenServer 5",
    "OPENSERVER_6": "SCO OpenServer 6",
    "UNIXWARE_7": "SCO UnixWare 7",
    "DARWIN": "Mac OS 10.5",
    "DARWIN_64": "Mac OS 10.5 (64 bit)",
    "DARWIN_10": "Mac OS 10.6",
    "DARWIN_10_64": "Mac OS 10.6 (64 bit)",
    "DARWIN_11": "Mac OS 10.7",
    "DARWIN_11_64": "Mac OS 10.7 (64 bit)",
    "DARWIN_12_64": "Mac OS 10.8 (64 bit)",
    "DARWIN_13_64": "Mac OS 10.9 (64 bit)",
    "DARWIN_14_64": "Mac OS 10.10 (64 bit)",
    "DARWIN_15_64": "Mac OS 10.11 (64 bit)",
    "DARWIN_16_64": "Mac OS 10.12 (64 bit)",
    "DARWIN_17_64": "Mac OS 10.13 (64 bit). This constant was added in vSphere API 6.7",
    "DARWIN_18_64": "Mac OS 10.14 (64 bit). This constant was added in vSphere API 6.7",
    "VMKERNEL": "VMware ESX 4",
    "VMKERNEL_5": "VMware ESX 5",
    "VMKERNEL_6": "VMware ESX 6",
    "VMKERNEL_65": "VMware ESX 6.5",
    "OTHER": "Other Operating System",
    "OTHER_64": "Other Operating System (64 bit) (experimental)",
}

export default {
    /**
     * Get all VMs
     * @param {boolean} full=false With guest extension and folder infos (need more time)
     * @returns {Promise}
     */
    getAll(full = false) {
        return new Promise((resolve, reject) => {
            const queryOpt = full ? {params: {guestInfos: true, folderInfo: true}} : undefined;
            api().get("vCenter/vms", queryOpt).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get VM by id
     * @param {string} id vCenter VM ID
     * @returns {Promise}
     */
    get(id) {
        return new Promise((resolve, reject) => {
            api().get("vCenter/vms/" + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete VM by id
     * @param {string} id vCenter VM ID
     * @returns {Promise}
     */
    delete(id) {
        return new Promise((resolve, reject) => {
            api().delete("vCenter/vms/" + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Send an action to vCenter (reset, stop, start, shutdown, restart)
     * @param {string} id vCenter VM ID
     * @param {string} action Action which called
     * @returns {Promise}
     */
    sendPowerAction(id, action){
        return new Promise((resolve, reject) => {
            api().post(`vCenter/vms/${id}/power/${action}`, {}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Translate guest_os to correct name
     * @param os guest_os attribute from VM object
     * @returns {string}
     */
    translateOS(os){
        return osList[os];
    },
    /**
     * Map VM state to word, color and icon
     * @param {object} data VM Object
     * @param {string} attr="state" Which attribute returns (state, color, icon)
     * @returns {string}
     */
    getState(data, attr="state") {
        if(data === null){
            data = {};
        }
        const props = {
            state: 'available',
            color: 'cyan',
            icon: 'check'
        }
        switch (data.power_state) {
            case 'SUSPENDED':
                props['state'] = 'suspend';
                props['color'] = 'orange';
                props['icon'] = 'pause';
                break;
            case 'RESET':
                props['state'] = 'reset';
                props['color'] = 'warning';
                props['icon'] = 'redo';
                break;
            case 'POWERED_OFF':
                props['state'] = 'stop';
                props['color'] = 'danger';
                props['icon'] = 'stop';
                break;
            case 'POWERED_ON':
                props['state'] = 'starting';
                props['color'] = 'olive';
                props['icon'] = 'play';
                break;
        }
        if (data.power_state === 'POWERED_ON' && data.run_state === 'RUNNING') {
            props['state'] = 'start';
            props['color'] = 'success';
            props['icon'] = 'play';
        }
        return props[attr];
    },
    /**
     * Evaluate OS from OS string and get category back
     * @param {string} guest_OS VM object attribute "guest_os"
     * @returns {string}
     */
    evaluateOs(guest_OS) {
        if (guest_OS === undefined) guest_OS = "";
        let os = "undefined";
        if (guest_OS.match(/windows/i)) {
            os = "windows";
        } else if (guest_OS.match(/ubuntu/i)) {
            os = "ubuntu";
        } else if (guest_OS.match(/suse/i)) {
            os = "suse";
        } else if (guest_OS.match(/centos/i)) {
            os = "centos";
        } else if (guest_OS.match(/redhat/i)) {
            os = "redhat";
        } else if (guest_OS.match(/linux/i)) {
            os = "linux";
        }
        return os;
    },
    /**
     * Evaluate OS from OS string and get icon back
     * @param {string} guest_OS VM object attribute "guest_os"
     * @returns {string[]}
     */
    getOsTypeIcon(guest_OS) {
        let type = "";
        switch (this.evaluateOs(guest_OS)) {
            case 'windows':
                type = ['fab', 'windows'];
                break;
            case 'ubuntu':
                type = ['fab', 'ubuntu'];
                break;
            case 'suse':
                type = ['fab', 'suse'];
                break;
            case 'centos':
                type = ['fab', 'centos'];
                break;
            case 'redhat':
                type = ['fab', 'redhat'];
                break;
            case 'linux':
                type = ['fab', 'linux'];
                break;
            default:
                type = ['far', 'question-circle'];
        }
        return type;
    },
    /**
     * Evaluate OS from OS string and get color variant back
     * @param {string} guest_OS VM object attribute "guest_os"
     * @returns {string}
     */
    getOsColor(guest_OS) {
        let color = "";
        switch (this.evaluateOs(guest_OS)) {
            case 'windows':
                color = 'text-cyan';
                break;
            case 'ubuntu':
                color = 'text-orange';
                break;
            case 'suse':
                color = 'text-success';
                break;
            case 'redhat':
                color = 'text-danger';
                break;
            case 'linux':
                color = 'text-dark';
                break;
            default:
                color = 'text-dark';
        }
        return color;
    },
};
