import store from "@/store/store"

export default {
    /**
     * Get API Url
     * @returns {string}
     */
    apiUrl(){
        return store.getters.getApi ? store.getters.getApi : process.env.VUE_APP_API
    },
    /**
     * Return the OS information from actual user
     * @returns {object} {{name: string, type: string, version: string, architecture: (string)}}
     */
    getOS() {
        // system
        let nVer = navigator.appVersion;
        let nAgt = navigator.userAgent;
        let os = 'unknown';
        let clientStrings = [
            {s: 'Windows 3.11', r: /Win16/},
            {s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/},
            {s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/},
            {s: 'Windows 98', r: /(Windows 98|Win98)/},
            {s: 'Windows CE', r: /Windows CE/},
            {s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/},
            {s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/},
            {s: 'Windows Server 2003', r: /Windows NT 5.2/},
            {s: 'Windows Vista', r: /Windows NT 6.0/},
            {s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/},
            {s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/},
            {s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/},
            {s: 'Windows 10', r: /(Windows 10|Windows NT 10.0)/},
            {s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
            {s: 'Windows ME', r: /Windows ME/},
            {s: 'Android', r: /Android/},
            {s: 'Open BSD', r: /OpenBSD/},
            {s: 'Sun OS', r: /SunOS/},
            {s: 'Ubuntu', r: /(X11; Ubuntu;)/},
            {s: 'Linux', r: /(Linux|X11)/},
            {s: 'iOS', r: /(iPhone|iPad|iPod)/},
            {s: 'Mac OS X', r: /Mac OS X/},
            {s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
            {s: 'QNX', r: /QNX/},
            {s: 'UNIX', r: /UNIX/},
            {s: 'BeOS', r: /BeOS/},
            {s: 'OS/2', r: /OS\/2/},
            {s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
        ];
        for (let id in clientStrings) {
            let cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        let osVersion = 'unknown';
        let osType = 'lin';

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
            osType = "win";
        }

        if (/Mac OS/.test(os)) {
            osType = "mac";
        }

        switch (os) {
            case 'Mac OS X':
                osVersion = /Mac OS X (10[._\d]+)/.exec(nAgt)[1];
                break;

            case 'Android':
                osVersion = /Android ([._\d]+)/.exec(nAgt)[1];
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
                break;

        }
        return {
            name: os,
            version: osVersion,
            type: osType,
            architecture: nAgt.match(/x86_64|x64/) ? 'x64' : 'x86'
        };
    },
    /**
     * Get Browser infos from actual user
     * @returns {object} {{name: string, majorVersion: number, version: string}}
     */
    getBrowser() {
        let nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;

        // In Opera 15+, the true version is after "OPR/"
        if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 4);
        }
        // In older Opera, the true version is after "Opera" or after "Version"
        else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) !== -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) !== -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        return {
            name: browserName,
            version: fullVersion,
            majorVersion: majorVersion
        };
    },

    /**
     * Create, click and remove a hidden link, redirect user to this link
     * @param {string} url Url to redirect
     * @param {string} target='_blank' Target attr from a-Tag
     */
    openLink(url, target = '_blank') {
        let elem = document.createElement('a');
        elem.setAttribute('href', url);
        elem.setAttribute('target', target);
        elem.style.display = 'none';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    },

    /**
     * Download file
     * @param {string} filename Filename
     * @param {string} data Data as Base64
     * @param {string} mineType='text/plain' Mime type
     */
    download(filename, data, mineType = "text/plain") {
        let elem = document.createElement('a');
        elem.setAttribute('href', 'data:' + mineType + ';charset=utf-8,' + encodeURIComponent(data));
        elem.setAttribute('download', filename);
        elem.style.display = 'none';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    },

    /**
     * Download file as link
     * @param {string} url
     * @param {string} filename
     */
    downloadLink(url, filename) {
        let elem = document.createElement('a');
        elem.setAttribute('href', url);
        elem.setAttribute('download', filename);
        elem.style.display = 'none';
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    },

    /**
     * Download File from Blob-Data
     * @param {object} file
     * @param {object} response
     */
    downloadBlob(file, response) {
        const blob = new Blob([response.data], {type: file.mimetype});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file.name;
        link.click();
        URL.revokeObjectURL(link.href);
    },

    /**
     * Copy data to clipboard
     * @param {string|number} data
     */
    copyToClipboard(data) {
        let tempInput = document.createElement("input");
        tempInput.value = data;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
    },

    /**
     * Get actual selected language
     * @returns {string}
     */
    getLocale() {
        return localStorage.getItem("locale") ? localStorage.getItem("locale") : 'de';
    },

    /**
     * Convert an array to an object
     * @param array
     * @param key
     * @returns {*}
     */
    convertArrayToObject(array, key) {
        const initialValue = {};
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item
            };
        }, initialValue);
    },

    /**
     * Parse String to JSON if possible
     * @param {string} string
     * @returns {*}
     */
    parseJSON(string) {
        try {
            string = JSON.parse(string);
        } catch (e) {
            return string;
        }
        return string;
    },

    /**
     * Get or Set an attribute from object by string
     * @param {object} obj Object to iterate
     * @param {string|array} is Search String/Array a.b.c
     * @param {string} value=undefined Set a value to search array/string
     * @returns {*}
     */
    getAttrFromString(obj, is, value) {
        if (typeof is == 'string')
            return this.getAttrFromString(obj, is.split('.'), value);
        else if (is.length == 1 && value !== undefined)
            return obj[is[0]] = value;
        else if (is.length == 0)
            return obj;
        else
            return this.getAttrFromString(obj[is[0]], is.slice(1), value);
    }
};
