<template>
    <b-row>
        <b-col cols="2" class="text-center">
            <font-awesome-icon :class="getOsColor()" :icon="getOsTypeIcon()" size="4x"/>
        </b-col>
        <b-col>
            <h5>Pool Info</h5>
            <hr>
            <table class="width-100">
                <tr>
                    <td>Pool:</td>
                    <td>{{ data.name }}</td>
                </tr>
                <tr>
                    <td>OS:</td>
                    <td>{{ translateOS(data.imageId.guest_OS) }}</td>
                </tr>
                <tr>
                    <td>Typ:</td>
                    <td>
                        <b-badge :class="[data.type === 'static' ? 'bg-purple' : 'bg-maroon', 'mr-1']">{{ $t('pages.vdi.pools.values.type.' + data.type) }}</b-badge>
                    </td>
                </tr>
                <tr>
                    <td>CPU:</td>
                    <td v-if="data.imageId.cpu">{{ data.imageId.cpu.count }} {{ $t('pages.myResources.cores') }}</td>
                </tr>
                <tr>
                    <td>RAM:</td>
                    <td v-if="data.imageId.memory">{{ data.imageId.memory.size_MiB / 1024 }} GiB</td>
                </tr>
            </table>
        </b-col>
        <b-col>
            <h5>Session Info</h5>
            <hr>
            <table class="width-100" v-if="connectedVm !== null">
                <tr v-if="connectedVm.power_state">
                    <td>Status:</td>
                    <td>
                        <b-badge :class="['', getState(true)]">{{ $t('states.' + getState()) }}</b-badge>
                    </td>
                </tr>
                <tr v-if="connectedVm.name">
                    <td>VM Name:</td>
                    <td>{{ connectedVm.name }}</td>
                </tr>
                <tr v-if="connectionData">
                    <td>IP Adresse:</td>
                    <td>{{ connectionData.host }}</td>
                </tr>
                <tr v-if="connectedVm.nics">
                    <td>MAC Adresse:</td>
                    <td v-if="connectedVm.nics['4000']">{{ connectedVm.nics['4000'].mac_address }}</td>
                </tr>
            </table>
            <span v-else>Keine Session vorhanden...</span>
        </b-col>
    </b-row>

</template>

<script>
import vmService from "../../services/vmService";

export default {
    name: "poolDetails",
    props: ["data", "session"],
    data: () => ({
        vmId: undefined,
        connectedVm: null,
        connectionData: undefined,
    }),
    mounted() {
        if(this.session){
            this.vmId = this.session.vmId;
            this.connectedVm = this.session.connectedVm;
            this.connectionData = this.session.connectionData;
        }
    },
    methods: {
        translateOS(os) {
            return vmService.translateOS(os);
        },
        getState(attr) {
            return vmService.getState(this.connectedVm, attr);
        },
        getOsTypeIcon(){
            return vmService.getOsTypeIcon(this.data.imageId.guest_OS);
        },
        getOsColor(){
            return vmService.getOsColor(this.data.imageId.guest_OS);
        },
    }
};
</script>

<style scoped>

</style>
